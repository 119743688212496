import { BatchTask } from "@ollie-sports/firebase";
import { AccountId, Coupon, License, OrgId, TeamPlanCost } from "@ollie-sports/models";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import express from "express";
import moment from "moment";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { addStripeLicense, renewLicense } from "../../internal-utils/license-utils";
import { formatMoneyValue } from "../../internal-utils/money-utils";
import { validateToken } from "../../internal-utils/server-auth";
import { SendMessageToSlackChannel, emitMarketingEvent } from "../../utils";
import { coupon__server__useCoupon } from "../coupon";
import { miscEmails__server__paymentReceiptEmail } from "../miscEmails";
import { couponsFn } from "./helpers";

interface Props {
  orgName: string;
  orgId: OrgId;
  creditCardToken: string;
  coupon?: Coupon;
  email: string;
  name: string;
  stripeAccountId?: string;
  ollieAccountId?: AccountId;
  amountToCharge: number;
  discount?: number;
  paymentTitle: string;
  paymentMetadata?: Record<string, string | number>;
  applicationFeeAmount?: number;
  phoneNumber: string;
  shouldSendReceipt: boolean;
  locale: string;
  idempotencyKey: string;
}

export async function billing__server__proccessPaymentToOrg(
  this: ServerThisContext,
  p: Props
): Promise<{ type: "success"; receiptURL: string | null } | { type: "error"; errorMsg: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const {
    channels,
    injectedServerLibraries: { stripe }
  } = getServerHelpers();

  const couponString =
    p.coupon && p.discount
      ? this.translate(
          { defaultMessage: `coupon {couponId} applied for a savings of {moneyVal}` },
          { couponId: p.coupon.id, moneyVal: formatMoneyValue(p.discount) }
        )
      : "";

  try {
    // Charge the credit card

    const customer = await stripe.customers.create(
      {
        name: p.name,
        email: p.email,
        phone: p.phoneNumber,
        source: p.creditCardToken
      },
      {
        stripeAccount: p.stripeAccountId
      }
    );

    let r1 = await stripe.charges.create(
      {
        amount: p.amountToCharge,
        currency: "usd",
        description: `${p.paymentTitle}${couponString ? ` (${couponString})` : ""}`,
        metadata: p.paymentMetadata,
        application_fee_amount: p.applicationFeeAmount ?? 0,
        receipt_email: p.email,
        customer: customer.id
      },
      {
        stripeAccount: p.stripeAccountId,
        idempotencyKey: p.idempotencyKey
      }
    );

    if (r1.status === "succeeded") {
      olliePipe.emitEvent({
        type: "analytic-stripe-payment-to-org-success",
        payload: { stripeId: r1.id, stripeAmount: r1.amount, orgId: p.orgId, name: p.name, email: p.email }
      });
      if (p.coupon && p.discount) {
        olliePipe.emitEvent({
          type: "analytic-stripe-payment-to-org-coupon-discount",
          payload: {
            stripeId: r1.id,
            stripeAmount: r1.amount,
            orgId: p.orgId,
            name: p.name,
            email: p.email,
            couponCode: p.coupon.id,
            discount: p.discount / 100
          }
        });
        coupon__server__useCoupon({
          couponId: p.coupon.id
        });
      }
      let tasks: BatchTask[] = [];

      if (p.email && r1.receipt_url && p.shouldSendReceipt) {
        await miscEmails__server__paymentReceiptEmail({
          name: p.name.split(" ")[0],
          email: p.email,
          receiptUrl: r1.receipt_url
        });
      }

      await h._BatchRunner.executeBatch(tasks);

      return { type: "success", receiptURL: r1.receipt_url };
    }
    return { type: "error", errorMsg: "" };
  } catch (e: any) {
    olliePipe.emitEvent({
      type: "stripe-payment-to-org-error",
      payload: { orgId: p.orgId, email: p.email, name: p.name, amount: p.amountToCharge }
    });
    SendMessageToSlackChannel({
      channel: "#important-errors",
      message: `
      ERROR Processing Stripe Payment!!
      Org: ${p.orgName}
      Payload: ${JSON.stringify(p)}
    `
    });

    let errorMsg = this.translate({
      defaultMessage: "There was a problem charging your card. Please try again or contact support@olliesports.com",
      serverLocale: p.locale
    });
    if (e?.raw?.code) {
      switch (e.raw.code) {
        case "card_declined":
          errorMsg = this.translate({
            defaultMessage: "Your card was declined.",
            serverLocale: p.locale
          });
          break;
        case "expired_card":
          errorMsg = this.translate({
            defaultMessage: "Your card has expired.",
            serverLocale: p.locale
          });
          break;
        case "incorrect_cvc":
          errorMsg = this.translate({
            defaultMessage: "Incorrect CVC.",
            serverLocale: p.locale
          });
          break;
        case "incorrect_number":
          errorMsg = this.translate({
            defaultMessage: "Incorrect Card Number.",
            serverLocale: p.locale
          });
          break;
        case "incorrect_zip":
          errorMsg = this.translate({
            defaultMessage: "Incorrect Zip Code.",
            serverLocale: p.locale
          });
          break;
        case "insufficient_funds":
          errorMsg = this.translate({
            defaultMessage: "Insufficient Funds.",
            serverLocale: p.locale
          });
          break;
        case "invalid_number":
          errorMsg = this.translate({
            defaultMessage: "Invalid Card Number.",
            serverLocale: p.locale
          });
          break;
        default:
          errorMsg = this.translate({
            defaultMessage: "Your card was declined.",
            serverLocale: p.locale
          });
      }
      errorMsg = errorMsg + " " + this.translate({ defaultMessage: "Please update your information and try again." });
    }

    return { type: "error", errorMsg: errorMsg };
  }
  // SERVER_ONLY_TOGGLE
}

billing__server__proccessPaymentToOrg.auth = async (r: express.Request) => {};
// i18n certified - complete
